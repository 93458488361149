import * as React from "react";

import Layout from "../component/Layout";
import { NFTList, NoNFT } from "../component/NFTList";
import HeaderPage from "../component/Profile/HeaderPage";
import { Helmet } from "react-helmet-async";
import useProfile from "../hooks/use-profile";
import { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { AuthContext } from "../context/auth/authContext";
import * as styles from "../styles/MyNFT.module.scss";
import Spinner from "../component/Spinner";

const MyNFT = () => {
  const { getNFT, collectableNFT, seasonNFT, isSeasonTokenExists, isLoading } = useProfile();
  const { isAuthentificated } = React.useContext(AuthContext);
  const isReadyPage = React.useRef(false);
  // useEffect(() => {
  //   if (!isAuthentificated) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    isReadyPage.current = true;
  }, []);
  useEffect(() => {
    getNFT();
  }, []);
  return (
    <>
      <Helmet>
        <title> My NFT - Reds Club</title>
        <meta property='og:title' content='My NFT  - Reds Club' />
        <meta name='description' content='My NFT ' />
        <meta property='og:description' content='My NFT' />
      </Helmet>
      <Layout>
        <main className={styles.container}>
          <section className={styles.myNFT}>
            <HeaderPage title='my nft' />

            {isSeasonTokenExists ? (
              <div className={styles.content}>
                <NFTList header='Collectible cards' nfts={collectableNFT} isLoading={isLoading} />
                <div style={{ marginTop: "100px" }} />
                <NFTList header='membership cards' nfts={seasonNFT} isLoading={isLoading} />
              </div>
            ) : (
              <>
                {isLoading && <Spinner />}
                {!isLoading && isReadyPage.current && (
                  <NoNFT title='Here will be placed your NFT cards when you earn them' />
                )}
              </>
            )}
          </section>
        </main>
      </Layout>
    </>
  );
};

export default MyNFT;
