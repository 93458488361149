import * as React from "react";
import * as styles from "./NFTList.module.scss";
import { FC, PropsWithChildren } from "react";
import Skeleton from "../Loader/Loader";
import Card from "./Card/";
import { INft } from "../../context/profile/profileReducer";

type PropsType = {
  header: string;
  nfts: INft[];
  isLoading: boolean;
};

const NFTList: FC<PropsType> = ({ header, nfts, isLoading }) => {
  return (
    <div className={styles.nftList}>
      <div className={styles.header}>{header}</div>
      <div className={styles.nftItems}>
        {!isLoading ? (
          nfts.length > 0 ? (
            nfts.map((el) => {
              return (
                <Card
                  nftName={el.name}
                  key={el.image}
                  image={el.image}
                  attributes={el.attributes}
                />
              );
            })
          ) : (
            <div className={styles.text}> You don't have {header}</div>
          )
        ) : (
          [...new Array(3)].map((item, index) => (
            <Skeleton key={`skeleton-${index}`} />
          ))
        )}
      </div>
    </div>
  );
};

export default NFTList;
