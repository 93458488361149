import * as React from "react";
import * as styles from "./Card.module.scss";
import { FC } from "react";
import { AttributeNFT } from "../../../context/profile/profileReducer";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";

type PropsType = {
  nftName: string;
  image: string;
  attributes: AttributeNFT[];
};

const Card: FC<PropsType> = ({ nftName, image, attributes }) => {
  const season = attributes.find((el) => el.trait_type === "Season");
  return (
    <div className={styles.card}>
      <div className={styles.imageBlock}>
        <img className={styles.image} src={image} alt="avatar" />
      </div>
      <div className={styles.nftName}>
        {nftName} {season && `(Season ${season?.value})`}
      </div>
    </div>
  );
};

export default Card;
